// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klub-js": () => import("./../../../src/pages/klub.js" /* webpackChunkName: "component---src-pages-klub-js" */),
  "component---src-pages-mojstri-js": () => import("./../../../src/pages/mojstri.js" /* webpackChunkName: "component---src-pages-mojstri-js" */),
  "component---src-pages-pasovi-js": () => import("./../../../src/pages/pasovi.js" /* webpackChunkName: "component---src-pages-pasovi-js" */),
  "component---src-pages-slike-js": () => import("./../../../src/pages/slike.js" /* webpackChunkName: "component---src-pages-slike-js" */)
}

